<template>
  <v-card width="100%">
    <v-card-title class="text-h5">Poison incident reporting form</v-card-title>
    <v-card-text>
      <p>{{ $t('report.intro') }}</p>
      <v-form>
        <v-text-field :label="$t('report.namesurname')" v-model="name_surname" required />
        <v-text-field :label="$t('report.agencyOrganization')"  v-model="agencyOrganization" />
        <v-text-field :label="$t('report.town')" v-model="town" />
        <v-text-field :label="$t('report.phone')" v-model="phone" required />
        <v-text-field :label="$t('report.email')" v-model="email" />
        <v-textarea :label="$t('report.learnOfEvent')" v-model="learnOfEvent" />
        <v-text-field :label="$t('report.dateOfEvent')" v-model="dateOfEvent" required />
        <p class="text-h4">{{ $t('report.eventLocation') }}</p>
        <v-text-field :label="$t('report.country')" v-model="country" required />
        <v-text-field :label="$t('report.region')" v-model="region" required />
        <v-text-field :label="$t('report.county')"  v-model="county" />
        <v-text-field :label="$t('report.municipality')"  v-model="municipality" />
        <v-text-field :label="$t('report.lat')"  v-model="lat" />
        <v-text-field :label="$t('report.lng')"  v-model="lng" />
        <v-select :label="$t('report.deadAnimalInvolved')" v-model="deadAnimalInvolved" :items="choices"/>
        <v-textarea :label="$t('report.noAnimalAndState')" v-model="noAnimalAndState" />
        <v-select :label="$t('report.baitFound')" v-model="baitFound" :items="choices" />
        <v-textarea :label="$t('report.noBaitsAndType')" v-model="noBaitsAndType" />
        <v-checkbox :label="$t('report.terms')" v-model="terms" required />
      </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn color="green darken-1" text @click="submit" block>
            {{ $t('report.submit')}}
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: "ReportComp",
    data: () => ({
        name_surname: '',
        agencyOrganization: '',
        town: '',
        phone: '',
        email: '',
        learnOfEvent: '',
        dateOfEvent: '',
        country: '',
        region: '',
        county: '',
        municipality: '',
        lat: '',
        lng: '',
        deadAnimalInvolved: '',
        noAnimalAndState: '',
        baitFound: '',
        noBaitsAndType: '',
        terms: '',
        choices: [
          'Yes',
          'No',
          "I don't know"
        ]
    }),
    methods: {
      ...mapActions({
        createReport: 'reports/createReport'
      }),
      async submit() {
        const payload = {
          "name_surname": this.name_surname,
          "agency_organization": this.agencyOrganization,
          "town": this.town,
          "phone": this.phone,
          "email": this.email,
          "learnOfEvent": this.learnOfEvent,
          "dateOfEvent": this.dateOfEvent,
          "country": this.country,
          "region": this.region,
          "county": this.county,
          "municipality": this.municipality,
          "lat": parseFloat(this.lat),
          "lng": parseFloat(this.lng),
          "deadAnimalInvolved": this.deadAnimalInvolved,
          "noAnimalAndState": this.noAnimalAndState,
          "baitFound": this.baitFound,
          "noBaitsAndType": this.noBaitsAndType,
          "terms": this.terms
        }
        await this.createReport(payload)
        this.$emit('report-complete', 'this is the success message')
      }
    },
    computed: {}
}
</script>

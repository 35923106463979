<template>
    <div class="ma-1 pa-1">
        <v-toolbar light>
            <v-toolbar-title>
                Manage Reports
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-data-table :headers="headers" :items="reports">
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item.id)">
                    mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="getConfirm(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <!-- START - CONFIRM DELETE DIALOG -->
        <v-dialog
            v-model="deletedialog"
            persistent
            width="350"
            :ripple="foo"
            style="z-index: 1301"
        >
            <v-card>
                <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
                    <v-toolbar-title>Confirm Report Delete</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p>You are about to delete a Report. If you are sure,
                        type the following string:</p>
                    <p class="justify-center text-center" style="background-color: #e6e6e6">
                        <pre>{{ todelete }}</pre>
                    </p>
                    <p>in the text input below and press confirm.</p>
                    <v-form
                        v-model="valid"
                        ref="confirmform"
                        lazy-validation
                    > 
                        <v-text-field
                            v-model="deletecode"
                            :rules="confirmRule"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" @click.stop="delcancel">Cancel</v-btn>
                    <v-btn color="warning" @click.stop="delconfirm">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END - CONFIRM DELETE DIALOG -->

    </div>
</template>

<script>
import ReportComp from '@/components/ReportComp'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "Reports",
    components: {
        ReportComp
    },
    data: () => ({
        search: '',
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        headers: [
            {
                text: 'ID',
                value: 'id',
                sortable: true
            },
            {
                text: 'Name/Surname',
                value: 'name_surname',
            },
            {
                text: 'Country',
                value: 'country'
            },
            {
                text: 'Dead Animal Involved',
                value: 'deadAnimalInvolved'
            },
            {
                text: 'Bait Found',
                value: 'baitFound'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            }
        ],
        // START DELETE DIALOG
        valid: false,
        deletedialog: false,
        foo: false,
        todelete: "",
        delreportid: "",
        deletecode: ""
        // END DELETE DIALOG
    }),
    watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        }
    },
    created () {
        this.initialize()
    },
    computed: {
        ...mapGetters({
            reports: 'reports/getReports'
        }),
        confirmRule() {
            return [
                () => (this.todelete === this.deletecode) || 'Value does not match requested code',
                v => !!v || 'Fill value to confirm'
            ];
        },
    },
    methods: {
        ...mapActions({
            // editItem: 'reports/updateReport',
            deleteReport: 'reports/deleteReport'
        }),
        initialize () {
            this.$store.dispatch('reports/getReports')
        },
        // editItem (item) {
        //     console.log(`Attempt to edit: ${item}`)
        //     this.editedIndex = this.$store.state.reports.reports.indexOf(item)
        //     this.editedItem = Object.assign({}, item)
        //     this.dialog = true
        // },
        editItem (id) {
            this.$store.dispatch('reports/getReport', parseInt(id))
            this.$router.push({name:'View Report', params: {'id': id}})
        },
        deleteItem (item) {
            this.editedIndex = this.$store.state.reports.reports.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm () {
            this.deleteReport(this.editedItem.id)
            this.closeDelete()
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        // START CONFIRM DELETE METHODS
        getConfirm (item) {
            this.todelete = `delete report ${item.id}`
            this.delreportid = item.id
            this.deletedialog = true
        },
        delcancel () {
            this.$refs.confirmform.resetValidation()
            this.deletecode = ""
            this.todelete= ""
            this.delreportid = ""
            this.deletedialog = false
        },
        delconfirm () {
            if (this.$refs.confirmform.validate()) {
                this.deletecode = ""
                // delete the user
                this.$store.dispatch('reports/deleteReport', this.delreportid)
                    .then(console.log(true))
                    .catch(e => {
                        console.log(e)
                    })
            } else {
                this.foo = true
                console.log('the validation is not correct')
            }
            this.deletecode = ""
            this.delreportid = ""
            this.$refs.confirmform.resetValidation()
            this.deletedialog = false
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
                this.editItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        // END CONFIRM DELETE METHODS
    }

}
</script>
